import { getIncomeEstimate } from "./income_estimates";

// Checks whether an element (modal) has been scrolled most of the way to the
// bottom and then disableds the agree button if it has
function handleScrolling(modal) {
  const agreeButton = document.querySelector("#consent-to-act-agree-button");
  if (agreeButton) {
    const { scrollTop, scrollHeight } = modal;
    const { height } = modal.getBoundingClientRect();
    const threshold = scrollHeight - height - 50; // Buffer of 50px

    if (scrollTop >= threshold) {
      if (agreeButton.hasAttribute("disabled")) {
        agreeButton.removeAttribute("disabled");
      }
    }
  }
}

function updateStartingTaxRate({ selfEmployedIncome, salaryIncome, otherIncome }) {
  const startingIncomeTaxRate = document.getElementById("starting-income-tax-rate");

  if (startingIncomeTaxRate) {
    $.rails.ajax({
      type: "GET",
      url: Routes.get_starting_effective_tax_rate_starting_rates_calculator_index_path(),
      data: {
        self_employed_income: selfEmployedIncome,
        salary_income: salaryIncome,
        other_income: otherIncome,
      },
      success(data) {
        $("#starting-income-tax-rate").text(data.starting_effective_tax_rate);
      },
      error(data) {
        toastr.error("Sorry, we're unable to estimate your starting tax rate right now");
      },
    });
  }
}

function updateStartingStudentLoanRate(totalIncome) {
  const startingStudentLoanRate = document.getElementById("starting-student-loan-rate");

  if (startingStudentLoanRate) {
    $.rails.ajax({
      type: "GET",
      url: Routes.get_starting_student_loan_rate_starting_rates_calculator_index_path(),
      data: {
        total_income: totalIncome,
      },
      success(data) {
        $("#starting-student-loan-rate").text(data.starting_student_loan_rate);
      },
      error(data) {
        toastr.error("Sorry, we're unable to estimate your starting student loan rate right now");
      },
    });
  }
}

function updateTotalEstimatedIncome(totalIncome) {
  const totalEstimatedIncome = document.getElementById("confirm-page-total-income");

  if (totalEstimatedIncome) {
    const formattedIncome = currencyAmountWithCommas(totalIncome.toFixed(2));
    totalEstimatedIncome.innerText = formattedIncome;
  };
}

function updateIncomeForecastRates() {
  const incomeEstimate = getIncomeEstimate();

  if (incomeEstimate) {
    updateStartingTaxRate(incomeEstimate);
    updateStartingStudentLoanRate(incomeEstimate.totalIncome);
    updateTotalEstimatedIncome(incomeEstimate.totalIncome);
  }
}

const executedUpdateIncomeForecastRates = debounce(updateIncomeForecastRates, 500);

const replaceFormWithLoader = () => {
  const documentDetailsForm = document.querySelector("#sign-up-tour-form.enter-document-details");
  const loaderDiv = document.querySelector("#loader-div");

  documentDetailsForm.hidden = true;
  loaderDiv.hidden = false;
}

document.addEventListener("keyup", (e) => {
  const idsArray = ["self-employed-income-amount", "salary-income-amount", "other-income-amount"];
  if (idsArray.includes(e.target.id)) {
    executedUpdateIncomeForecastRates();
  }
});

// On scroll, if the scrolling element is the modal-body (which is case on desktop)
// or modal-content (on mobile), then check if the user has reached the bottom yet
document.addEventListener("scroll", (event) => {
  const { target } = event;
  if (target.classList && (target.classList.contains("modal-body") || target.classList.contains("modal-content"))) {
    handleScrolling(target);
  }
}, true);


window.addEventListener("transitionend", () => {
  const tourNextBtn = document.querySelector("#tour-next-btn");
  const documentDetailsForm = document.querySelector(".enter-document-details");

  if (tourNextBtn && documentDetailsForm) {
    tourNextBtn.removeEventListener("click", replaceFormWithLoader);
    tourNextBtn.addEventListener("click", replaceFormWithLoader);
  };
});

export { updateIncomeForecastRates };
