$(document).on("keyup", (e) => {
  const { id, value } = e.target;

  if (id === "user_home_office_percent") {
    const homeOfficePercent = value;
    const homeOfficePercentHighMessage = document.querySelector("#home-office-percent-over-message");

    if (homeOfficePercent > 25) {
      homeOfficePercentHighMessage.classList.remove("hidden");
    } else {
      homeOfficePercentHighMessage.classList.add("hidden");
    }
  } else if (id === "user_trading_name") {
    const alertBox = document.querySelector("#trading-name-warning");
    const allWords = value.split(" ");
    const forbiddenWords = ["limited", "ltd", "proprietary", "pty"];
    const list = allWords.filter((word) => forbiddenWords.includes(word.toLowerCase()));

    if (list.length > 0) {
      alertBox.classList.remove("hidden");
    } else {
      alertBox.classList.add("hidden");
    }
  }
});
