/**
 * Calls the native app to handle the "Manage biometrics" button click
 */
$(function () {
  $(document).on("click", "#manage-biometrics", function () {
    if (window.BiometricsCallback) {
      window.BiometricsCallback.manageBiometricsClicked();
    }
  });
});
